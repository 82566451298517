<template>
	<b-row>
		<b-col cols="12">
			<!-- 1 -->
			<b-card no-body class="mb-4">
				<h3 class="p-3">Role List</h3>
				<!-- Header -->
				<b-card-body class="p-3 d-flex justify-content-between">
					<b-form-group class="mb-2 w-25">
						<b-input-group>
							<b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>

							<b-input-group-append>
								<b-button variant="danger" :disabled="!filter" @click="filter = ''">Clear</b-button>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
					<div class="d-flex align-items-center">
						<b-button variant="primary" class="mr-2" v-b-modal.modal-rol @click="titleModal = 'Create New Rol'">New Rol</b-button>
					</div>
				</b-card-body>
				<!-- End Section -->

				<!-- Data Table -->
				<b-table
					style="min-height: 250px;"
					show-empty
					responsive
					small
					class="mb-0"
					head-variant="light"
					:items="roles"
					:filter="filter"
					:fields="fields"
					hover
				>
					<template #cell(id)="data"> #{{ data.item.id }} </template>
					<template #cell(isActive)="data">
						<b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
						<b-badge variant="danger" v-else>Inactive</b-badge>
					</template>
					<template #cell(action)="data">
						<b-dropdown variant="primary" id="dropdown-1" text="Actions" class="">
							<b-dropdown-item v-b-modal.modal-rol @click="editRole(data.item)">Edit role</b-dropdown-item>
							<b-dropdown-item @click="viewRole(data.item)">View role</b-dropdown-item>
							<b-dropdown-item v-b-modal.modal-disable @click="roleSelected = data.item">Disable role</b-dropdown-item>
							<b-dropdown-item v-b-modal.modal-add @click="roleSelected = data.item">Add permissions</b-dropdown-item>
							<b-dropdown-item v-b-modal.modal-remove @click="roleSelected = data.item">Remove permissions</b-dropdown-item>
						</b-dropdown>
					</template>
				</b-table>
				<!-- End Section -->

				<!-- Pagination -->
				<div class="p-3 d-flex align-items-center">
					<b-form-group
						label="Per page"
						label-for="per-page-select"
						label-cols-sm="6"
						label-cols-md="4"
						label-cols-lg="9"
						label-size="sm"
						label-class="fw-medium"
						class="mb-0"
					>
						<b-form-select @change="getRoles" id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
					</b-form-group>
					<div class="ml-auto">
						<b-pagination @input="getRoles" v-model="currentPage" :total-rows="totalRoles" :per-page="perPage" align="fill" class="my-0"></b-pagination>
					</div>
				</div>
				<!-- End Section -->
			</b-card>
		</b-col>

		<b-modal id="modal-rol" hide-footer centered size="lg" :title="titleModal" @close="closeModal">
			<b-form @submit.prevent="saveRole">
				<b-form-group label="Description" label-class="fw-medium mb-1" label-for="description">
					<b-form-input id="description" type="text" placeholder="Your description here" v-model="description" required></b-form-input>
				</b-form-group>

				<b-form-group required v-slot="{ ariaDescribedby }" label="Access to Screens" label-class="fw-medium mb-1" label-for="roles">
					<div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 4px;">
						<b-form-checkbox
							v-for="option in authorizations"
							:key="option.id"
							:value="option.id"
							v-model="permissions"
							name="screens"
							:aria-describedby="ariaDescribedby"
						>
							{{ option.description }}
						</b-form-checkbox>
					</div>
				</b-form-group>
				<b-button block :disabled="loadingRole" variant="primary" type="submit">
					<b-spinner small v-if="loadingRole"></b-spinner>
					<span v-else>Save Role</span>
				</b-button>
			</b-form>
		</b-modal>

		<b-modal id="modal-role-details" hide-footer centered title="Role Details">
			<div class="mb-4">
				<!-- Message -->
				<div class="border-bottom d-flex justify-content-between py-2">
					<h6 class="message-title text-dark mb-0 mt-1">Description</h6>
					<span class="mail-desc d-block text-muted">{{ roleSelected.description }}</span>
				</div>
				<div class="border-bottom d-flex justify-content-between py-2">
					<h6 class="message-title text-dark mb-0 mt-1">Created By</h6>
					<span class="mail-desc d-block text-muted">{{ roleSelected.createdBy }}</span>
				</div>
				<div class="border-bottom d-flex justify-content-between py-2">
					<h6 class="message-title text-dark mb-0 mt-1">Creation Date</h6>
					<span class="mail-desc d-block text-muted">{{ formatDate(roleSelected.createDate) }}</span>
				</div>
				<div class="border-bottom d-flex justify-content-between py-2">
					<h6 class="message-title text-dark mb-0 mt-1">Modified By</h6>
					<span class="mail-desc d-block text-muted">{{ roleSelected.modifiedBy }}</span>
				</div>
				<div class="border-bottom d-flex justify-content-between py-2">
					<h6 class="message-title text-dark mb-0 mt-1">Modified Date</h6>
					<span class="mail-desc d-block text-muted">{{ formatDate(roleSelected.modifiedDate) }}</span>
				</div>
			</div>
			<b-form-group v-slot="{ ariaDescribedby }" label="Access to Screens" label-class="fw-medium mb-1" label-for="roles">
				<b-form-checkbox
					disabled
					v-model="permissions"
					v-for="option in authorizations"
					:key="option.id"
					:value="option.id"
					name="screens"
					:aria-describedby="ariaDescribedby"
				>
					{{ option.description }}
				</b-form-checkbox>
			</b-form-group>
			<div class="d-flex justify-content-end">
				<b-button variant="secondary" @click="$bvModal.hide('modal-role-details')">Cancel</b-button>
			</div>
		</b-modal>

		<b-modal id="modal-add" size="lg" hide-footer centered title="Role Details">
			<b-card-text>
				Add permissions to <strong>{{ roleSelected.description }}</strong>
			</b-card-text>
			<b-form @submit.prevent="addPermissions">
				<b-form-group v-slot="{ ariaDescribedby }" label="Access to Screens" label-class="fw-medium mb-1" label-for="roles">
					<div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 4px;">
						<b-form-checkbox
							v-model="permissions"
							v-for="option in authorizations"
							:key="option.id"
							:value="option.id"
							name="screens"
							:aria-describedby="ariaDescribedby"
						>
							{{ option.description }}
						</b-form-checkbox>
					</div>
				</b-form-group>
				<b-button block :disabled="loadingRole" variant="primary" type="submit">
					<b-spinner small v-if="loadingRole"></b-spinner>
					<span v-else>Add permissions</span>
				</b-button>
			</b-form>
		</b-modal>

		<b-modal id="modal-disable" size="sm" hide-footer centered title="Disable Rol">
			<b-card-text>
				Are you sure to want to disable this role?
			</b-card-text>
			<div class="d-flex justify-content-end">
				<b-button variant="danger" class="mr-2" @click="disableRole">Yes</b-button>
				<b-button variant="secondary" @click="$bvModal.hide('modal-disable')">No</b-button>
			</div>
		</b-modal>

		<b-modal id="modal-remove" size="sm" hide-footer centered title="Remove authorizations">
			<b-card-text>
				Are you sure to want remove the authorizations of this role?
			</b-card-text>
			<div class="d-flex justify-content-end">
				<b-button variant="danger" class="mr-2" @click="removeAuthorizations">Yes</b-button>
				<b-button variant="secondary" @click="$bvModal.hide('modal-remove')">No</b-button>
			</div>
		</b-modal>
	</b-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "Roles",

	data: () => ({
		page: {
			title: "Roles",
		},
		filter: null,
		fields: [
			{
				key: "id",
				label: "No",
				sortable: true,
			},
			{
				key: "description",
				label: "Description",
				sortable: true,
			},
			{
				key: "isActive",
				label: "Status",
				sortable: true,
			},
			{
				key: "action",
				label: "Actions",
				sortable: false,
			},
		],

		totalRows: 1,
		currentPage: 1,
		perPage: 10,
		pageOptions: [5, 10, 20, 40, { value: 100, text: "Show a lot" }],
		titleModal: "Create Role",
		permissions: [],
		description: "",
		loadingRole: false,
		isEditing: false,
		roleSelected: {},
	}),
	components: {},
	methods: {
		onRowClicked(item) {
			this.$router.push(`/customers/${item.id}`);
		},
		clearValues() {
			this.description = "";
			this.permissions = [];
			this.roleSelected = {};
		},
		async getData() {
			await this.getRoles();
			await this.$store.dispatch("authorizations/get_allAuthorizations");
		},
		async getRoles() {
			let data = {
				skip: this.currentPage,
				take: this.perPage,
			};
			await this.$store.dispatch("roles/get_allRoles", data);
		},
		async editRole(role) {
			this.clearValues();
			const roleData = await this.$store.dispatch("roles/get_role", role.id);
			this.roleSelected = roleData;
			this.permissions = roleData.authorizations.map((auth) => auth.id);
			this.isEditing = true;
			this.description = role.description;
			this.titleModal = "Edit Role";
		},
		async viewRole(role) {
			this.clearValues();
			const roleData = await this.$store.dispatch("roles/get_role", role.id);
			this.roleSelected = roleData;
			this.permissions = roleData.authorizations.map((auth) => auth.id);
			this.$bvModal.show("modal-role-details");
		},
		async addPermissions() {
			this.loadingRole = true;
			await this.$store.dispatch("roles/add_roleAuthorization", {
				roleId: this.roleSelected.id,
				authIds: this.permissions,
				createdBy: 1,
			});
			await this.getRoles();
			this.$bvModal.hide("modal-add");
			this.clearValues();
			this.loadingRole = false;
		},
		async disableRole() {
			await this.$store.dispatch("roles/update_roleStatus", {
				id: this.roleSelected.id,
				isActive: false,
				modifiedBy: 1,
			});
			await this.getRoles();
			this.clearValues();
			this.$bvModal.hide("modal-disable");
		},
		async removeAuthorizations() {
			await this.$store.dispatch("roles/remove_roleAuthorization", {
				roleId: this.roleSelected.id,
			});
			await this.getRoles();
			this.clearValues();
			this.$bvModal.hide("modal-remove");
		},
		closeModal() {
			this.isEditing = false;
			this.clearValues();
			this.titleModal = "Create Role";
		},
		formatDate(date) {
			const d = new Date(date);
			return d.toLocaleDateString();
		},
		async saveRole() {
			this.loadingRole = true;
			if (this.isEditing) {
				const role = await this.$store.dispatch("roles/update_role", {
					id: this.roleSelected.id,
					description: this.description,
					modifiedBy: 1,
					isActive: true,
				});
				await this.$store.dispatch("roles/remove_roleAuthorization", {
					roleId: this.roleSelected.id,
				});
				await this.$store.dispatch("roles/add_roleAuthorization", {
					roleId: role.dataResult,
					authIds: this.permissions,
					createdBy: 1,
				});
				this.clearValues();
			} else {
				const role = await this.$store.dispatch("roles/add_role", {
					description: this.description,
					authIds: [],
					createdBy: 1,
					isActive: true,
				});
				await this.$store.dispatch("roles/add_roleAuthorization", {
					roleId: role.dataResult,
					authIds: this.permissions,
					createdBy: 1,
				});
				this.clearValues();
			}
			await this.getRoles();
			this.$bvModal.hide("modal-rol");
			this.loadingRole = false;
		},
	},
	computed: {
		...mapGetters({
			roles: "roles/getRoles",
			totalRoles: "roles/getTotalRoles",
			authorizations: "authorizations/getAuthorizations",
		}),
	},
	mounted() {},
	created() {
		this.getData();
	},
};
</script>
